import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { cn } from "~/utils/classnames";

type WrapperProps = PropsWithChildren<ComponentPropsWithoutRef<"div">>;

export function Wrapper({ children, className }: WrapperProps) {
  return (
    <div className={cn("mx-auto w-full max-w-[1500px] px-2 sm:px-4 md:px-6", className)}>
      {children}
    </div>
  );
}
